import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  AppstoreOutlined,
  UserOutlined,
  ApartmentOutlined,
  BankOutlined,
  SmileOutlined,
  FundProjectionScreenOutlined,
  DollarOutlined,
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/main",
    sidebar: {
      icon: <AppstoreOutlined />,
      label: "대시 보드",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "대시 보드",
        },
        component: loadable(() => import("../pages/Landing")),
      },
      {
        exact: true,
        path: "/payinfo",
        sidebar: {
          label: "결제 정보",
        },
        component: loadable(() => import("../pages/PayInfo")),
      },
    ],
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "회원리스트",
        },
        component: loadable(() => import("../pages/user")),
      },
      {
        exact: true,
        visible: false,
        path: "/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/promotion",
    sidebar: {
      icon: <ApartmentOutlined />,
      label: "프로모션 관리",
    },
    children: [
      {
        exact: true,
        path: "/event",
        sidebar: {
          label: "이벤트 내역",
        },
        component: loadable(() => import("../pages/promotion/Event")),
      },
      {
        exact: true,
        visible: false,
        path: "/event/insert",
        component: loadable(() => import("../pages/promotion/EventInsert")),
      },
      {
        exact: true,
        visible: false,
        path: "/event/:id",
        component: loadable(() => import("../pages/promotion/EventDetail")),
      },
      {
        exact: true,
        path: "/point",
        sidebar: {
          label: "적립금 지급 내역",
        },
        component: loadable(() => import("../pages/promotion/Point")),
      },
      {
        exact: true,
        path: "/coupon",
        sidebar: {
          label: "쿠폰 리스트",
        },
        component: loadable(() => import("../pages/promotion/Coupon")),
      },
      {
        exact: true,
        visible: false,
        path: "/coupon/insert",
        component: loadable(() => import("../pages/promotion/CouponInsert")),
      },
      {
        exact: true,
        visible: false,
        path: "/coupon/:id",
        component: loadable(() => import("../pages/promotion/CouponDetail")),
      },
      {
        exact: true,
        path: "/couponSend",
        sidebar: {
          label: "쿠폰 발급 리스트",
        },
        component: loadable(() => import("../pages/promotion/CouponSend")),
      },
      {
        exact: true,
        visible: false,
        path: "/couponSend/:id",
        component: loadable(() => import("../pages/promotion/CouponSend")),
      },
      {
        exact: true,
        visible: false,
        path: "/couponSend/detail/:id",
        component: loadable(() =>
          import("../pages/promotion/CouponSendDetail")
        ),
      },
      {
        exact: true,
        path: "/send",
        sidebar: {
          label: "쿠폰 발급",
        },
        component: loadable(() => import("../pages/promotion/Send")),
      },
      {
        exact: true,
        path: "/code",
        sidebar: {
          label: "할인코드 리스트",
        },
        component: loadable(() => import("../pages/promotion/Code")),
      },
      {
        exact: true,
        visible: false,
        path: "/code/insert",
        component: loadable(() => import("../pages/promotion/CodeInsert")),
      },
      {
        exact: true,
        visible: false,
        path: "/code/:id",
        component: loadable(() => import("../pages/promotion/CodeDetail")),
      },
    ],
  },
  {
    exact: true,
    path: "/bank",
    sidebar: {
      icon: <BankOutlined />,
      label: "문제은행 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "문제은행 리스트",
        },
        component: loadable(() => import("../pages/bank")),
      },
      {
        exact: true,
        visible: false,
        path: "/:id/:table",
        component: loadable(() => import("../pages/bank/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/insert",
        component: loadable(() => import("../pages/bank/Insert")),
      },
    ],
  },
  {
    exact: true,
    path: "/credit",
    sidebar: {
      icon: <UserOutlined />,
      label: "크레딧 관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "크레딧 카테고리",
        },
        component: loadable(() => import("../pages/credit/category/")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/:id",
        component: loadable(() => import("../pages/credit/category/Detail")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "크레딧 관리",
        },
        component: loadable(() => import("../pages/credit/creditlist/")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/credit/creditlist/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() =>
          import("../pages/credit/creditlist/Register")
        ),
      },
      {
        exact: true,
        path: "/subscribe",
        sidebar: {
          label: "구독권 관리",
        },
        component: loadable(() => import("../pages/credit/subscribe/")),
      },
      {
        exact: true,
        visible: false,
        path: "/subscribe/detail/:id",
        component: loadable(() => import("../pages/credit/subscribe/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/subscribe/register",
        component: loadable(() => import("../pages/credit/subscribe/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/test",
    sidebar: {
      icon: <FundProjectionScreenOutlined />,
      label: "테스트 관리",
    },
    children: [
      {
        exact: true,
        path: "/",
        sidebar: {
          label: "테스트 목록",
        },
        component: loadable(() => import("../pages/test/")),
      },

      {
        exact: true,
        visible: false,
        path: "/detail",
        component: loadable(() => import("../pages/test/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/question",
    sidebar: {
      icon: <SmileOutlined />,
      label: "문의 관리",
    },
    children: [
      {
        exact: true,
        path: "/",
        sidebar: {
          label: "문의 내역",
        },
        component: loadable(() => import("../pages/question/qna/")),
      },
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "FAQ 관리",
        },
        component: loadable(() => import("../pages/question/faq/")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/question/qna/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/video",
    sidebar: {
      icon: <SmileOutlined />,
      label: "영상 콘텐츠 관리",
    },
    children: [
      {
        exact: true,
        path: "/",
        sidebar: {
          label: "교육 콘텐츠 리스트",
        },
        component: loadable(() => import("../pages/video/class/")),
      },
      {
        exact: true,
        path: "/study",
        sidebar: {
          label: "학습 콘텐츠 리스트",
        },
        component: loadable(() => import("../pages/video/study/")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/video/class/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/study/register",
        component: loadable(() => import("../pages/video/study/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/study/detail/:id",
        component: loadable(() => import("../pages/video/study/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/class/detail/:id",
        component: loadable(() => import("../pages/video/class/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/usd",
    sidebar: {
      icon: <DollarOutlined />,
      label: "USD 환율 관리",
    },
    children: [
      {
        exact: true,
        path: "/",
        sidebar: {
          label: "USD 환율 관리",
        },
        component: loadable(() => import("../pages/usd")),
      },
    ],
  },
];
